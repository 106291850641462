/** @jsx jsx */
import { jsx, Link as TLink, Container } from "theme-ui";
import { Link, navigate } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import ArraySplit from "../../components/Util/ArraySplit";
import SkillTag from "../../components/Tags/SkillTag";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SocialIcon from "../../components/Icons/SocialIcon";

const ProjectShowcase = (props) => {
  const { project, leftJustified } = props;
  const { frontmatter, fields } = project;
  const isSmallScreen = useMediaQuery("(max-width: 800px)");
  const alternate = (toggle, items) => {
    return toggle ? (
      <>
        {items[0]}
        {items[1]}
      </>
    ) : (
      <>
        {items[1]}
        {items[0]}
      </>
    );
  };
  const navToProj = (link) => () => {
    navigate(link);
  };
  const imgBlock = (
    <div sx={{ position: `relative` }}>
      <div
        role="button"
        tabIndex="0"
        onClick={navToProj(`${fields.slug}`)}
        onKeyPress={navToProj(`${fields.slug}`)}
        sx={{
          ":hover": {
            cursor: `pointer`,
          },
          ":hover:before": {
            opacity: 0,
            // transform: `translate(1rem, 1rem)`,
          },
          ":before": {
            content: '""',
            backgroundColor: "primary",
            position: `absolute`,
            width: "100%",
            height: "100%",
            zIndex: 1,
            opacity: 0.3,
            top: `-1rem`,
            left: `-1rem`,
            transition: `opacity 0.3s ease, transform 0.3s ease`,
          },
          ":hover .gatsby-image-wrapper": {
            "-webkit-filter": `grayscale(0)`,
            filter: `grayscale(0)`,
          },
        }}
      >
        <Img
          // style={{
          //   maxHeight: 300,
          //   "-webkit-filter": `grayscale(1)`,
          //   filter: `grayscale(1)`,
          //   transition: `filter 0.3s ease`,
          // }}
          sx={{
            maxHeight: 300,
            "-webkit-filter": `grayscale(1)`,
            filter: `grayscale(1)`,
            transition: `filter 0.3s ease`,
          }}
          fluid={project.sharpImage && project.sharpImage.fluid}
          alt=""
        />
      </div>
    </div>
  );

  const descBlock = (
    <div
      sx={{
        textAlign: leftJustified ? "left" : "right",
        gridRowStart: isSmallScreen ? 2 : "",
      }}
    >
      <h2>{frontmatter.title}</h2>
      <div sx={{ margin: "0 -0.75rem" }}>
        <ArraySplit
          arr={frontmatter.tags.map((t) => (
            <SkillTag name={t} />
          ))}
          sep=" - "
        />
      </div>
      <Container variant="styles">
        <div dangerouslySetInnerHTML={{ __html: frontmatter.summary }} />
      </Container>

      <div sx={{ margin: "0 -0.5rem" }}>
        {frontmatter.live_url && (
          <TLink
            href={frontmatter.live_url}
            target="_blank"
            rel="noopener noreferrer"
            as="a"
          >
            <SocialIcon iconClass="fas" iconName="external-link-alt" />
          </TLink>
        )}
        {frontmatter.source_url && (
          <TLink
            href={frontmatter.source_url}
            target="_blank"
            rel="noopener noreferrer"
            as="a"
          >
            <SocialIcon iconClass="fab" iconName="github" />
          </TLink>
        )}
      </div>
    </div>
  );

  return (
    <div
      sx={{
        display: `grid`,

        gridTemplateColumns: `repeat(${
          isSmallScreen ? "1" : "2"
        }, minmax(300px, 1fr))`,
        gridColumnGap: `2rem`,
        padding: `2rem 1rem`,
        position: `relative`,
        gridAutoFlow: `dense`,
        "::before": {
          content: '""',
          position: `absolute`,
          zIndex: -1,
          top: `${isSmallScreen ? "25%" : "0"}`,
          left: `${isSmallScreen ? "0" : `${leftJustified ? "25%" : "0"}`}`,
          right: `${isSmallScreen ? "0" : `${leftJustified ? "0" : "25%"}`}`,
          // right: `0rem`,
          bottom: `0rem`,
          border: `2px solid`,
        },
      }}
    >
      {alternate(leftJustified, [imgBlock, descBlock])}
    </div>
  );
};

export default ProjectShowcase;
