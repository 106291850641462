/** @jsx jsx */
import { jsx, Heading, Button } from "theme-ui";

import { Component } from "react";
import Headshot from "./Headshot";
import config from "../../../data/SiteConfig";

class Hero extends Component {
  render() {
    return (
      <div
        sx={{
          display: `grid`,
          gridTemplateColumns: `repeat(auto-fit, minmax(300px, 1fr))`,
          gridColumnGap: `1rem`,
        }}
      >
        <Headshot />
        <div>
          <Heading sx={{ fontSize: 7 }}>Hi, I'm Loren. </Heading>
          <Heading variant="styles.h4" sx={{ color: "primary" }}>
            <span>
              full-stack dev{" "}
              <span role="img" aria-label="pancake">
                🥞
              </span>
            </span>
          </Heading>
          <p sx={{ fontSize: [1, 2] }}>
            I&#39;m a software engineer from SoCal. When I&#39;m not coding, I
            enjoy recipe crafting, fishing, climbing, or napping with my cat, Miso.
          </p>
          <Button
            variant="buttons.outline"
            sx={{ fontSize: 2 }}
            href={`mailto:${config.userEmail}`}
            as="a"
          >
            Get in touch
            {/* <FontAwesomeIcon icon={["fas", "paper-plane"]} /> */}
          </Button>
        </div>
      </div>
    );
  }
}

export default Hero;
