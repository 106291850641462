/** @jsx jsx */
import { jsx } from "theme-ui";

import React, { Component } from "react";

const SkillTag = (props) => {
  const { name } = props;
  return (
    <span
      sx={{
        padding: `0rem .75rem`,
        color: `primary`,
        fontFamily: `monospace`
      }}
    >
      {name}
    </span>
  );
};

export default SkillTag;
