/** @jsx jsx */
import { jsx } from "theme-ui";
import Helmet from "react-helmet";

import { Component } from "react";
import { graphql } from "gatsby";
import config from "../../data/SiteConfig";

import Layout from "../layout";
import Projects from "../components/Projects/Projects";
import Hero from "../components/Hero/Hero";
import Career from "../components/Career/Career";

const getAnnotatedMarkdown = (mdEdges, fileEdges) => {
  const imagePaths = mdEdges.map((edge) => edge.node.frontmatter.cover);
  const images = fileEdges.filter((edge) =>
    imagePaths.includes(edge.node.relativePath)
  );
  const dict = {};
  images.forEach((edge) => {
    const relPath = edge.node.relativePath;
    if (relPath) {
      dict[relPath] = edge;
    }
  });

  return mdEdges.map((edge) => ({
    ...edge.node,
    sharpImage:
      dict[edge.node.frontmatter.cover] &&
      dict[edge.node.frontmatter.cover].node.childImageSharp,
  }));
};

class HomePage extends Component {
  render() {
    const markdownEdges = this.props.data.allMarkdownRemark.edges;
    const fileEdges = this.props.data.allFile.edges;

    const projectEdges = markdownEdges.filter((edge) =>
      edge.node.fileAbsolutePath.includes("/content/projects")
    );

    const projects = getAnnotatedMarkdown(projectEdges, fileEdges);

    const postEdges = markdownEdges
      .filter((edge) => edge.node.fileAbsolutePath.includes("/content/posts"))
      .slice(0, 3);

    const experiencesEdges = markdownEdges
      .filter((edge) =>
        edge.node.fileAbsolutePath.includes("/content/experiences")
      )
      .sort((a, b) => {
        return (
          new Date(b.node.frontmatter.end_date) -
          new Date(a.node.frontmatter.end_date)
        );
      });
    // const experiences = getAnnotatedMarkdown(experiencesEdges, fileEdges);
    const experiences = experiencesEdges.map((edge) => edge.node);
    const sectionStyles = { minHeight: `80vh`, py: [5] };
    return (
      <Layout>
        <Helmet title={`${config.siteTitle}`} />

        <section sx={sectionStyles}>
          <Hero />
        </section>
        <section sx={sectionStyles}>
          <hr />

          <Career
            experiences={experiences.filter((e) => e.fields.shown_on_home)}
          />
        </section>
        <section sx={sectionStyles}>
          <hr />

          <Projects projects={projects.filter((p) => p.fields.shown_on_home)} />
        </section>
        {/* <hr />
        <section sx={sectionStyles}>
          <Heading variant="styles.h2" sx={{ display: `inline-block` }}>
            recent posts
          </Heading>
          <TLink
            as={Link}
            to="/posts"
            sx={{ px: 3, fontWeight: 700, fontFamily: `heading` }}
          >
            // view all
          </TLink>

          <PostListing postEdges={postEdges} />
        </section> */}
      </Layout>
    );
  }
}

export default HomePage;

export const query = graphql`
  query homeQuery {
    allFile {
      edges {
        node {
          id
          name
          relativePath
          publicURL
          childImageSharp {
            fixed(width: 300, height: 300) {
              ...GatsbyImageSharpFixed
            }
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(sort: { fields: [fields___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
            date
            shown_on_home
            html_summary
          }
          excerpt
          timeToRead
          fileAbsolutePath
          frontmatter {
            title
            summary
            start_date
            end_date
            company
            category
            cover
            date
            tags
            slug
            live_url
            source_url
          }
        }
      }
    }
  }
`;
