/** @jsx jsx */
import { jsx, Heading, Container } from "theme-ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../../data/SiteConfig";

import SkillTag from "../Tags/SkillTag";
import ArraySplit from "../Util/ArraySplit";
import HorizontalTabs from "../MaterialUI/ScrollableHorizontalTabs";

// import "./About.css";
import { getMonthYear } from "../../utils/Date";

const Career = (props) => {
  //   const isLargeScreen = useMediaQuery("(min-width: 1200px)");
  const { experiences } = props;
  const experiencesTabs = experiences.map((e) => e.frontmatter.company);
  const experiencesContents = experiences.map((e) => (
    <div sx={{ padding: "15px" }}>
      <div sx={{ fontSize: [3], fontWeight: 700 }}>{e.frontmatter.title}</div>
      <span sx={{ fontSize: 1, color: `secondary` }}>
        {getMonthYear(new Date(e.frontmatter.start_date)).toLowerCase()} -{" "}
        {e.frontmatter.end_date.toLowerCase() === "present" ? "present" : getMonthYear(new Date(e.frontmatter.end_date)).toLowerCase()}
      </span>
      <Container variant="styles">
        {/* We want to take process html summary and display it as such */}
        <div dangerouslySetInnerHTML={{ __html: e.fields.html_summary }} />
      </Container>
    </div>
  ));
  //   const isSmallScreen = useMediaQuery("(max-width: 800px)");

  const backendSkills = config.userSkills
    .filter((skill) => skill.category === "backend")
    .map((s) => <SkillTag name={s.name} />);
  const frontendSkills = config.userSkills
    .filter((skill) => skill.category === "frontend")
    .map((s) => <SkillTag name={s.name} />);
  const buildSkills = config.userSkills
    .filter((skill) => skill.category === "build")
    .map((s) => <SkillTag name={s.name} />);

  return (
    <div className="career">
      <Heading variant="styles.h1">experiences</Heading>
      <div sx={{ fontSize: [2], pb: 5 }}>
        <h2 sx={{ color: `primary` }}> background </h2>
        <p>
          After graduating (UC Berkeley `18, BS Bioengineering), I completed a
          summer research fellowship at the Biomimetic Millisystems Lab and then
          worked at UCSF as a research associate. During my time within
          academia, I&apos;ve always used and enjoyed programming. In developing
          software for research and personal projects, I delved into app
          development and decided to pursue it as a career.
        </p>
        <div>
          Here are some technologies under my tool belt:
          <br />
          <div sx={{ display: `inline-block`, minWidth: 25 }}>
            <FontAwesomeIcon icon="database"> </FontAwesomeIcon>
          </div>{" "}
          //
          <ArraySplit arr={backendSkills} sep="-" />
          <br />
          <div sx={{ display: `inline-block`, minWidth: 25 }}>
            <FontAwesomeIcon icon="code"> </FontAwesomeIcon>
          </div>{" "}
          //
          <ArraySplit arr={frontendSkills} sep="-" />
          <br />
          <div sx={{ display: `inline-block`, minWidth: 25 }}>
            <FontAwesomeIcon icon="tools"> </FontAwesomeIcon>
          </div>{" "}
          //
          <ArraySplit arr={buildSkills} sep="-" />
        </div>
      </div>

      <div sx={{ fontSize: [2] }}>
        <div sx={{ variant: `dividers.bottom`, width: "100%" }} />
        <h2 sx={{ color: `primary` }}> work </h2>
        <HorizontalTabs tabs={experiencesTabs} contents={experiencesContents} />
        {/* {isSmallScreen ? (
          <HorizontalTabs
            tabs={experiencesTabs}
            contents={experiencesContents}
          />
        ) : (
          <VerticalTabs tabs={experiencesTabs} contents={experiencesContents} />
        )} */}
      </div>
    </div>
  );
};

export default Career;
