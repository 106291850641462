import React from "react";

const ArraySplit = ({ arr, sep }) => {
  const out = arr.map((item, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <span key={`item-${i}`}>
      {item}
      {arr.length - 1 === i ? "" : sep}
    </span>
  ));
  return out;
};

export default ArraySplit;
