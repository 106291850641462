/** @jsx jsx */
import { jsx, Heading, Link as TLink } from "theme-ui";

import React from "react";
import { Link } from "gatsby";
import ProjectShowcase from "./ProjectShowcase";

const Projects = (props) => {
  const { projects } = props;
  return (
    <section>
      <Heading variant="styles.h1" sx={{ display: `inline-block` }}>
        projects
      </Heading>
      <TLink
        as={Link}
        to="/projects/software"
        sx={{ px: 3, fontWeight: 700, fontFamily: `heading` }}
        // eslint-disable-next-line react/jsx-no-comment-textnodes
      >
        // view all
      </TLink>

      {projects &&
        projects.map((proj, i) => {
          return (
            <div sx={{ padding: `2rem 0rem` }}>
              <ProjectShowcase project={proj} leftJustified={i % 2 === 0} />
            </div>
          );
        })}
    </section>
  );
};

export default Projects;
