/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = (context) =>
  makeStyles((theme) => {
    return {
      root: {
        flexGrow: 1,
        width: "100%",
        minHeight: 400,
        fontFamily: context.fonts.body,
        backgroundColor: context.colors.subtleBackground
      },

      // @global is handled by jss-plugin-global.
      "@global": {
        // You should target [class*="MuiButton-root"] instead if you nest themes.
        ".MuiTab-root": {
          fontSize: context.fontSizes[2],
          textTransform: "none",
          fontFamily: context.fonts.heading,
          fontWeight: 700,
          color: context.colors.text,
          borderBottom: `1px solid ${context.colors.secondary}`,
          padding: `1rem`,
        },
        ".MuiTabs-flexContainer": {
        //   justifyContent: `center`,
        },
        ".MuiTabs-indicator": {
          backgroundColor: context.colors.primary,
        },
        ".MuiTab-root.Mui-selected": {
          color: context.colors.primary,
        },
        ".MuiAppBar-root": {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
        ".MuiTypography-root": {
          fontFamily: context.fonts.body,
          fontWeight: 400,
          fontSize: context.fontSizes[2],
        },
        ".MuiBox-root": {
          padding: `12px 24px`,
        },
        ".MuiTabScrollButton-root": {
          color: context.colors.secondary,
        },
      },
    };
  });

export default function ScrollableTabsButtonAuto(props) {
  const context = useThemeUI();
  const uiTheme = context.theme;
  const classes = useStyles(uiTheme)();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { tabs } = props;
  const { contents } = props;

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable auto tabs example"
        >
          {tabs.map((t, i) => (
            <Tab label={t} {...a11yProps(i)} />
          ))}
        </Tabs>
      </AppBar>
      {contents.map((c, i) => (
        <TabPanel value={value} index={i}>
          {c}
        </TabPanel>
      ))}
    </div>
  );
}
