/** @jsx jsx */
import { jsx } from "theme-ui";

import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

const Headshot = () => {
  const headshotQuery = useStaticQuery(graphql`
    query MyQuery {
      allFile(filter: { relativePath: { regex: "/headshot_golden_gate/" } }, limit: 1) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  const headshotImage = headshotQuery.allFile.edges[0].node.childImageSharp;
  return (
    <div
      sx={{
        margin: `2rem`,
        maxWidth: `500px`,
        position: `relative`,
        "::before": {
          content: '""',
          position: `absolute`,
          zIndex: 0,
          top: `-1rem`,
          left: `-1rem`,
          right: `1rem`,
          bottom: `1rem`,
          border: `3px solid`,
        },
        "::after": {
          content: '""',
          position: `absolute`,
          zIndex: 0,
          backgroundColor: "primary",
          width: "100%",
          height: "100%",
          opacity: 0.2,
          top: `1rem`,
          left: `1rem`,
          right: `-1rem`,
          bottom: `-1rem`,
          // border: `3px solid`,
          // color: `primary`,
        },
        // "& .headshot-img": {
        //   zIndex: 1,
        // }
      }}
    >
      <Img
        className="headshot-img"
        fluid={headshotImage.fluid}
        alt={headshotImage.id}
      />
    </div>
  );
};

export default Headshot;
